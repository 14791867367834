import React from 'react';
import { Pagination } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

const Paginations = ({ lastIndex, active, setActive }) => {
  return (
    <>
      <Pagination
        className='mt-4'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Pagination.Prev
          onClick={() => {
            active > 1
              ? setActive(active - 1)
              : toast.success(`This is the first page!`, {
                  duration: 2000,
                  icon: '⚠️',
                });
          }}
        />
        <Pagination.Item disabled>{active}</Pagination.Item>
        <Pagination.Next
          onClick={() => {
            active < lastIndex
              ? setActive(active + 1)
              : toast.success(`This is the last page!`, {
                  duration: 2000,
                  icon: '⚠️',
                });
          }}
        />
      </Pagination>
      <Toaster />
    </>
  );
};

export default Paginations;
