import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import * as ROUTES from '../../../constants/routes';

import { useFirebase } from '../../../contexts/FirebaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import { AddFundamental, DeleteFundamental } from '../FundamentalComponents';

function Session() {
  const [fundamentals, setFundamentals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docLength, setDocLength] = useState(0);

  const { getAllFundamentals } = useFirebase();
  const { admin } = useAuth();

  const trackPath = useParams().track;
  const sessionPath = useParams().session;
  const trackId = trackPath.charAt(0).toUpperCase() + trackPath.slice(1);
  const sessionId = sessionPath.charAt(0).toUpperCase() + sessionPath.slice(1);

  useEffect(() => {
    setLoading(true);
    fetchAllFundamentals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllFundamentals = async () => {
    await getAllFundamentals(trackId, sessionId)
      .then((list) => {
        setDocLength(list.docs.length);

        list.docs.forEach((doc) => {
          let path = doc.id.toLowerCase();
          fundamentals.push({ ...doc.data(), id: doc.id, path: path });
        });
        setFundamentals(fundamentals);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Col>
        {admin && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AddFundamental
              track={trackId}
              session={sessionId}
              docLength={docLength}
            />
            <DeleteFundamental track={trackId} session={sessionId} />
          </div>
        )}
        {!loading &&
          fundamentals.map((fundamental) => (
            <Row className='justify-content-center d-flex align-items-center ml-2 mr-2 mt-2'>
              <div key={fundamental.id}>
                <Button
                  style={{ height: '45px', width: '700px' }}
                  variant='outline-primary'
                  className='w-30 mt-1 mr-2'
                  as={Link}
                  to={ROUTES.TRACKS.concat('/', trackPath)
                    .concat('/', sessionPath)
                    .concat('/', fundamental.path)}
                  href={ROUTES.TRACKS.concat('/', trackPath)
                    .concat('/', sessionPath)
                    .concat('/', fundamental.path)}
                >
                  {fundamental.name}
                </Button>
              </div>
            </Row>
          ))}
      </Col>
    </div>
  );
}

export default Session;
