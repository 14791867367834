import React, { useState } from 'react';
import { Form, Button, Modal, Col, Alert } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';

const RequestModal = ({ request, show, handleClose, error }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      phoneNumber: request.phoneNumber,
      club: request.club,
      dateOfBirth: {
        day: request.dateOfBirth.day,
        month: request.dateOfBirth.month,
        year: request.dateOfBirth.year,
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const { deleteRequest } = useFirebase();
  const { signUp } = useAuth();

  const onAccept = async (data) => {
    setLoading(true);
    await signUp(data.firstName, data.lastName, data.email)
      .then(deleteRequest(request.id))
      .then(() => {
        toast.success(
          `${data.firstName}'s request has been accepted. The account has just been created.`,
          {
            duration: 2000,
          }
        );
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.message, {
          duration: 2000,
        });
      });
  };

  const onDecline = async () => {
    setLoading(true);
    await deleteRequest(request.id);
    toast.error(`${request.firstName}'s request has been declined.`, {
      duration: 2000,
    });
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <Modal
        data-backdrop='static'
        data-keyboard='false'
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit(onAccept)}>
            <br></br>

            {/* First name */}
            <Form.Group controlId='readOnlySignUpFirstName'>
              <Col>
                <Form.Control
                  readOnly
                  type='text'
                  name='firstName'
                  placeholder='First Name'
                  ref={register({ required: false })}
                />
              </Col>
            </Form.Group>

            {/* Last name */}
            <Form.Group controlId='readOnlySignUpLastName'>
              <Col>
                <Form.Control
                  readOnly
                  type='text'
                  name='lastName'
                  placeholder='Last Name'
                  ref={register({ required: false })}
                />
              </Col>
            </Form.Group>

            {/* Email Address */}
            <Form.Group controlId='readOnlySignUpEmail'>
              <Col>
                <Form.Control
                  readOnly
                  type='email'
                  placeholder='name@example.com'
                  name='email'
                  ref={register({ required: false })}
                />
              </Col>
            </Form.Group>

            {/* Phone Number */}
            <Form.Group controlId='readOnlySignUpPhoneNumber'>
              <Col>
                <Form.Control
                  readOnly
                  type='text'
                  placeholder='Phone Number'
                  name='phoneNumber'
                  ref={register({ required: false })}
                />
              </Col>
            </Form.Group>

            {/* Club Name */}
            <Form.Group controlId='readOnlySignUpClubName'>
              <Col>
                <Form.Control
                  readOnly
                  type='text'
                  placeholder='Football Club'
                  name='club'
                  ref={register({ required: false })}
                />
              </Col>
            </Form.Group>

            {/* Date of Birth */}
            <Form.Label className='ml-3'>Date of Birth</Form.Label>
            <Form.Row>
              <Form.Group
                as={Col}
                className='ml-3'
                controlId='readOnlySignUpDateOfBirthDay'
              >
                <Form.Control
                  readOnly
                  type='text'
                  placeholder='Day'
                  name='dateOfBirth.day'
                  ref={register({ required: false })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId='readOnlySignUpDateOfBirthMonth'>
                <Form.Control
                  readOnly
                  type='text'
                  placeholder='Month'
                  name='dateOfBirth.month'
                  ref={register({ required: false })}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className='mr-3'
                controlId='readOnlySignUpDateOfBirthYear'
              >
                <Form.Control
                  readOnly
                  type='text'
                  placeholder='Year'
                  name='dateOfBirth.year'
                  ref={register({ required: false })}
                />
              </Form.Group>
            </Form.Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button disabled={loading} variant='primary' type='submit'>
                Accept
              </Button>
              <Button
                disabled={loading}
                className='ml-1'
                variant='danger'
                onClick={onDecline}
              >
                Decline
              </Button>
            </div>
            <Toaster />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestModal;
