import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// initialize firebase app using the necessary information stored in the env file
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementID: process.env.REACT_APP_MEASUREMENT_ID,
});

// firebase.functions().useEmulator('localhost', 5001);

export const auth = app.auth();
export const db = app.firestore();
export const functions = app.functions();
export default app;
