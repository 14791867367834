import React from 'react';
import { useState } from 'react';
import { Form, Button, Card, Alert, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import * as ROUTES from '../../constants/routes';

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { resetPassword } = useAuth();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setMessage('');
    setError('');

    resetPassword(email)
      .then(() => {
        setEmail('');
        setLoading(true);
        setMessage('Check your inbox for further instructions');
      })
      .catch((error) => {
        setError(error.message);
      });

    setLoading(false);
  };

  return (
    <Container
      fluid
      className='justify-content-center d-flex align-items-center'
      style={{
        height: '92vh',
      }}
    >
      <Card style={{ minWidth: '35%', maxWidth: '400px' }}>
        <Card.Body>
          <h2 className='text-center mb-4'>Password Reset</h2>
          {/* if error is true, shows error */}
          {error && <Alert variant='danger'>{error}</Alert>}
          {/* if there's a message, show message */}
          {message && <Alert variant='success'>{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id='email'>
              <Form.Control
                type='email'
                name='email'
                placeholder='Email'
                value={email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* if loading is true, Button is disabled */}
            <Button disabled={loading} className='w-100' type='submit'>
              Reset Password
            </Button>
          </Form>
          <div className='w-100 text-center mt-2'>
            <Link to={ROUTES.SIGN_IN}>Back</Link>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
