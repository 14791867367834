import React, { useState } from 'react';
import { Alert, Button, Modal, Col, Form, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFirebase } from '../../../contexts/FirebaseContext';

const AddContent = ({ track, session, fundamental, lesson, lastIndex }) => {
  const [showAddContent, setShowAddContent] = useState(false);
  const [error, setError] = useState('');
  const { updateContent } = useFirebase();

  const { register, reset, handleSubmit } = useForm();

  const removeEmptyStrings = (obj) => {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop] && obj[prop].length !== 0) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };

  const removeEmptyObjects = (obj) => {
    for (var key in obj) {
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
    return obj;
  };

  const onSubmit = async (data) => {
    if (data.page === '' && !data.newPage) {
      return setError('Choose a page.');
    }

    setError('');

    let page;

    data.newPage
      ? (page = `Page-${lastIndex + 1}`)
      : (page = `Page-${data.page}`);

    let options = [data.option1, data.option2, data.option3, data.option4];
    options = options.filter((option) => option);

    let mcq = {
      answer: data.mcq.answer,
      options: options,
      question: data.mcq.question,
    };
    mcq = removeEmptyStrings(mcq);

    let video = {
      path: data.video.path,
      title: data.video.title,
    };
    video = removeEmptyStrings(video);

    let image = {
      path: data.image.path,
      title: data.image.title,
    };
    image = removeEmptyStrings(image);

    let content = {
      explanation: data.explanation,
      image: image,
      mcq: mcq,
      video: video,
    };
    content = removeEmptyStrings(content);
    content = removeEmptyObjects(content);

    await updateContent(track, session, fundamental, lesson, page, content)
      .then(reset())
      .then(() => {
        let message;
        data.newPage ? (message = 'added') : (message = 'updated');

        toast.success(`Content in ${page} has been ${message}!`, {
          duration: 2000,
          icon: '👏',
        });
      })
      .then(
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button className='mt-2 mb-2' onClick={() => setShowAddContent(true)}>
          Add/Update Content
        </Button>
      </div>

      <Modal
        size='xl'
        data-backdrop='static'
        data-keyboard='false'
        animation={true}
        show={showAddContent}
        onHide={() => setShowAddContent(!showAddContent)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Update Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                {/* Question */}
                <Form.Group controlId='formAddContentQuestion'>
                  <Form.Label>MCQ</Form.Label>
                  <Form.Control
                    type='text'
                    name='mcq.question'
                    placeholder='Question'
                    ref={register({ required: false })}
                  />
                </Form.Group>

                {/* Option 1 */}
                <Form.Group controlId='formAddContentOption1'>
                  <Form.Control
                    type='text'
                    name='option1'
                    placeholder='Option 1'
                    ref={register({ required: false })}
                  />
                </Form.Group>

                {/* Option 2 */}
                <Form.Group controlId='formAddContentOption2'>
                  <Form.Control
                    type='text'
                    name='option2'
                    placeholder='Option 2'
                    ref={register({ required: false })}
                  />
                </Form.Group>

                {/* Option 3 */}
                <Form.Group controlId='formAddContentOption3'>
                  <Form.Control
                    type='text'
                    name='option3'
                    placeholder='Option 3'
                    ref={register({ required: false })}
                  />
                </Form.Group>

                {/* Option 4 */}
                <Form.Group controlId='formAddContentOption4'>
                  <Form.Control
                    type='text'
                    name='option4'
                    placeholder='Option 4'
                    ref={register({ required: false })}
                  />
                </Form.Group>

                {/* Answer */}
                <Form.Group controlId='formAddContentAnswer'>
                  <Form.Control
                    type='text'
                    name='mcq.answer'
                    placeholder='Answer'
                    ref={register({ required: false })}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Row>
                  <Col>
                    {/* Pages */}
                    <Form.Label>Page</Form.Label>
                    <Form.Group controlId='formAddContentPage'>
                      <Form.Control
                        type='text'
                        name='page'
                        placeholder='What page? (e.g. 1,2,3,...)'
                        ref={register({ required: false })}
                      />
                    </Form.Group>

                    {/* New Page */}
                    <Form.Group controlId='formAddContentNewPage'>
                      <Form.Check
                        inline
                        type='checkbox'
                        label='New Page'
                        name='newPage'
                        ref={register({ required: false })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Explanation</Form.Label>

                    {/* Explanation */}
                    <Form.Group controlId='formAddContentExplanation'>
                      <Form.Control
                        as='textarea'
                        name='explanation'
                        placeholder='...'
                        ref={register({ required: false })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col>
                    <Form.Label>Video</Form.Label>

                    {/* Video Title */}
                    <Form.Group controlId='formAddContentVideoTitle'>
                      <Form.Control
                        type='text'
                        name='video.title'
                        placeholder='Video Title'
                        ref={register({ required: false })}
                      />
                    </Form.Group>

                    <Form.Label>Video Path</Form.Label>
                    {/* Video Path */}
                    <Form.Group controlId='formAddContentVideoPath'>
                      <Form.Control
                        as='textarea'
                        name='video.path'
                        placeholder='/Folder/SubFolder/...'
                        ref={register({ required: false })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Image</Form.Label>

                    {/* Image Title */}
                    <Form.Group controlId='formAddContentImageTitle'>
                      <Form.Control
                        type='text'
                        name='image.title'
                        placeholder='Image Title'
                        ref={register({ required: false })}
                      />
                    </Form.Group>

                    <Form.Label>Image Path</Form.Label>
                    {/* Video Path */}
                    <Form.Group controlId='formAddContentImagePath'>
                      <Form.Control
                        as='textarea'
                        name='image.path'
                        placeholder='/Folder/SubFolder/...'
                        ref={register({ required: false })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button className='w-25' variant='primary' type='submit'>
                  Submit
                </Button>
                <Toaster />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddContent;
