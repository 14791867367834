import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Form, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogo from '../Static/HeaderLogo';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { useAuth } from '../../contexts/AuthContext';
import { useFirebase } from '../../contexts/FirebaseContext';

const Navigation = () => {
  const { currentUser, admin, subscribed } = useAuth();

  // if currentUser is authenticated(logged in) return NavigationAuth else NavigationNonAuth
  return (
    <Navbar
      collapseOnSelect
      bg='light'
      variant='light'
      expand='lg'
      className='container-fluid'
    >
      {currentUser ? (
        <NavigationAuth admin={admin} subscribed={subscribed} />
      ) : (
        <NavigationNonAuth />
      )}
    </Navbar>
  );
};

function NavigationAuth({ admin, subscribed }) {
  const [showTracks, setShowTracks] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tracks, setTracks] = useState([]);

  const { getAllTracks } = useFirebase();

  useEffect(() => {
    setLoading(true);
    getAllTracks()
      .then((list) => {
        list.docs.forEach((doc) => {
          let path = doc.id.replace(/\s+/g, '-').toLowerCase();
          tracks.push({ name: doc.data().name, path: path });
          setTracks(tracks);
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar.Brand as={Link} to={ROUTES.HOME} href={ROUTES.HOME}>
        <HeaderLogo />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      {!loading && (
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav justify className='mr-auto'>
            {(subscribed || admin) && (
              <NavDropdown
                title='Tracks'
                id='collapsible-nav-dropdown'
                show={showTracks}
                onMouseEnter={() => setShowTracks(true)}
                onMouseLeave={() => setShowTracks(false)}
              >
                <NavDropdown.Item
                  as={Link}
                  to={ROUTES.TRACKS}
                  href={ROUTES.TRACKS}
                >
                  All Tracks
                </NavDropdown.Item>
                {tracks.map((track) => (
                  <div key={track.name}>
                    <NavDropdown.Item
                      as={Link}
                      to={ROUTES.TRACKS.concat('/', track.path)}
                      href={ROUTES.TRACKS.concat('/', track.path)}
                    >
                      {track.name}
                    </NavDropdown.Item>
                  </div>
                ))}
              </NavDropdown>
            )}

            <NavDropdown
              title='Account'
              id='collapsible-nav-dropdown'
              show={showAccount}
              onMouseEnter={() => setShowAccount(!showAccount)}
              onMouseLeave={() => setShowAccount(false)}
            >
              <NavDropdown.Item
                as={Link}
                to={ROUTES.UPDATE_ACCOUNT}
                href={ROUTES.UPDATE_ACCOUNT}
              >
                Update Account
              </NavDropdown.Item>
            </NavDropdown>

            {admin && (
              <NavDropdown
                title='Admin'
                id='collapsible-nav-dropdown'
                show={showAdmin}
                onMouseEnter={() => setShowAdmin(!showAdmin)}
                onMouseLeave={() => setShowAdmin(false)}
              >
                <NavDropdown.Item
                  as={Link}
                  to={ROUTES.SIGN_UP}
                  href={ROUTES.SIGN_UP}
                >
                  Sign Up
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={ROUTES.MAKE_ADMIN}
                  href={ROUTES.MAKE_ADMIN}
                >
                  Make Admin
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={ROUTES.REQUESTS}
                  href={ROUTES.REQUESTS}
                >
                  Requests
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
          <Form inline>
            <SignOutButton />
          </Form>
        </Navbar.Collapse>
      )}
    </>
  );
}

const NavigationNonAuth = () => (
  <>
    <Navbar.Brand as={Link} to={ROUTES.LANDING} href={ROUTES.LANDING}>
      <HeaderLogo />
    </Navbar.Brand>
    <Nav className='mr-auto'>
      <Nav.Link as={Link} to={ROUTES.SIGN_IN} href={ROUTES.SIGN_IN}>
        Sign In
      </Nav.Link>
    </Nav>
  </>
);

export default Navigation;
