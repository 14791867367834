import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import { Pages, Paginations } from '../PageComponents';
import { AddContent, DeleteContent } from '../ContentComponents';

const Lesson = () => {
  const [pages, setPages] = useState([]);
  const [lastIndex, setLastIndex] = useState();
  const [active, setActive] = useState(1);

  const { getAllContent } = useFirebase();
  const { admin } = useAuth();

  const trackPath = useParams().track;
  const sessionPath = useParams().session;
  const fundamentalPath = useParams().fundamental;
  const lessonPath = useParams().lesson;
  const trackId = trackPath.charAt(0).toUpperCase() + trackPath.slice(1);
  const sessionId = sessionPath.charAt(0).toUpperCase() + sessionPath.slice(1);
  const fundamentalId =
    fundamentalPath.charAt(0).toUpperCase() + fundamentalPath.slice(1);
  const lessonId = lessonPath.charAt(0).toUpperCase() + lessonPath.slice(1);

  useEffect(() => {
    fetchAllContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllContent = async () => {
    await getAllContent(trackId, sessionId, fundamentalId, lessonId).then(
      (list) => {
        setLastIndex(list.docs.length);
        setPages(
          list.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      }
    );
  };

  return (
    <Container>
      <Col>
        {admin && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AddContent
              lastIndex={lastIndex}
              track={trackId}
              session={sessionId}
              fundamental={fundamentalId}
              lesson={lessonId}
            />
            <DeleteContent
              track={trackId}
              session={sessionId}
              fundamental={fundamentalId}
              lesson={lessonId}
            />
          </div>
        )}

        {pages
          .filter((_, index) => index === active - 1)
          .map((page) => (
            <div className='mt-4' key={page.id}>
              <Pages page={page} lastIndex={lastIndex} />
            </div>
          ))}
        <Paginations
          lastIndex={lastIndex}
          active={active}
          setActive={setActive}
        />
      </Col>
    </Container>
  );
};

export default Lesson;
