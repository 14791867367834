import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PrivateRoute = ({ path, navigate, element }) => {
  const { currentUser } = useAuth();

  // if currentUser is not null, return this Route
  if (!!currentUser) {
    return <Route path={path} element={element} />;
  }

  // if currentUser is null(not authenticated), Navigate to navigate
  return <Navigate to={navigate} />;
};

export default PrivateRoute;
