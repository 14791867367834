import React, { useEffect, useState } from 'react';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

function Pages({ page }) {
  const [imageLink, setImageLink] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [correct, setCorrect] = useState('');
  const [message, setMessage] = useState('');
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchContent() {
    setLoading(true);
    let imagePath = page.image?.path;
    let videoPath = page.video?.path;

    if (imagePath) {
      let res = await fetch(
        `https://api.dropboxapi.com/2/files/get_temporary_link`,
        {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer rBw5qUfklX4AAAAAAAAAAa1NuU6jAKzWcWTk4Z4EIOBVX3FUPPrdjmW-XjcHtbUl`,
          },
          body: JSON.stringify({
            path: imagePath,
          }),
        }
      );
      // sets the temporary link to the link state
      let data = await res.json();
      setImageLink(data.link);
    }

    if (videoPath) {
      let res = await fetch(
        `https://api.dropboxapi.com/2/files/get_temporary_link`,
        {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer rBw5qUfklX4AAAAAAAAAAa1NuU6jAKzWcWTk4Z4EIOBVX3FUPPrdjmW-XjcHtbUl`,
          },
          body: JSON.stringify({
            path: videoPath,
          }),
        }
      );

      // sets the temporary link to the link state
      let data = await res.json();
      setVideoLink(data.link);
    }
    setLoading(false);
  }

  function submitAnswer(option) {
    if (page) {
      if (page.mcq.options[option] === page.mcq.answer) {
        setCorrect(true);
        setMessage(`${page.mcq.answer} is correct!`);
        setDisable(true);
      } else {
        setCorrect(false);
        setMessage(`Incorrect! Try again!`);
      }
    }
  }

  return loading ? (
    <div>loading...</div>
  ) : (
    <div>
      <Row className='align-items-center'>
        <Col>
          {page && <h3 style={{ textAlign: 'center' }}>{page.video?.title}</h3>}
          <ReactPlayer
            className='mt-3'
            controls
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            url={videoLink}
          />
        </Col>
        <Col>
          <div>{page && page?.explanation}</div>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col>
          {page && <h3 style={{ textAlign: 'center' }}>{page.image?.title}</h3>}
          {imageLink && (
            <img
              className='mt-3'
              height='400'
              width='650'
              src={imageLink}
              alt='new'
            />
          )}
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {correct && message && <Alert variant='success'>{message}</Alert>}
          {!correct && message && <Alert variant='danger'>{message}</Alert>}
          {page.mcq && (
            <>
              <Card>
                <Card.Body>
                  <Card.Title>{page && page.mcq?.question}</Card.Title>
                  <Button
                    disabled={disable}
                    className='w-100 text-center mt-2'
                    variant='outline-primary'
                    onClick={() => submitAnswer(0)}
                  >
                    {page.mcq.options && page.mcq?.options[0]}
                  </Button>
                  <Button
                    disabled={disable}
                    className='w-100 text-center mt-2'
                    variant='outline-primary'
                    onClick={() => submitAnswer(1)}
                  >
                    {page.mcq.options && page.mcq?.options[1]}
                  </Button>
                  <Button
                    disabled={disable}
                    className='w-100 text-center mt-2'
                    variant='outline-primary'
                    onClick={() => submitAnswer(2)}
                  >
                    {page.mcq.options && page.mcq?.options[2]}
                  </Button>
                  <Button
                    disabled={disable}
                    className='w-100 text-center mt-2'
                    variant='outline-primary'
                    onClick={() => submitAnswer(3)}
                  >
                    {page.mcq.options && page.mcq?.options[3]}
                  </Button>
                </Card.Body>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Pages;
