import React, { useState } from 'react';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFirebase } from '../../../contexts/FirebaseContext';

const DeleteContent = ({ track, session, fundamental, lesson }) => {
  const [showDeleteContent, setShowDeleteContent] = useState(false);
  const { updateContent, deleteContent } = useFirebase();

  const { register, reset, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    let content = {};
    let message = '';
    if (data.allContent) {
      content = { explanation: '', mcq: {}, video: {}, image: {} };
      message = `Page-${data.page} has been deleted!`;
      await deleteContent(
        track,
        session,
        fundamental,
        lesson,
        `Page-${data.page}`
      )
        .then(reset())
        .then(() => {
          toast.success(message, {
            duration: 2000,
            icon: '👏',
          });
        })
        .then(
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        )
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (data.explanation) content.explanation = '';
      if (data.mcq) content.mcq = {};
      if (data.video) content.video = {};
      if (data.image) content.image = {};
      message = `Content in Page-${data.page} has been deleted!`;

      await updateContent(
        track,
        session,
        fundamental,
        lesson,
        `Page-${data.page}`,
        content
      )
        .then(reset())
        .then(() => {
          toast.success(message, {
            duration: 2000,
            icon: '👏',
          });
        })
        .then(
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className='btn-danger ml-2 mt-2 mb-2'
          onClick={() => setShowDeleteContent(true)}
        >
          Delete Content
        </Button>
      </div>

      <Modal
        size='md'
        data-backdrop='static'
        data-keyboard='false'
        animation={true}
        show={showDeleteContent}
        onHide={() => setShowDeleteContent(!showDeleteContent)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                {/* Pages */}
                <Form.Label>Page</Form.Label>
                <Form.Group controlId='formDeleteContentPage'>
                  <Form.Control
                    type='text'
                    name='page'
                    placeholder='What page? (e.g. 1,2,3,...)'
                    ref={register({ required: false })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {/*Multiple content*/}
                <Form.Group controlId='formDeleteMultipleContent'>
                  <Form.Label>Delete multiple content</Form.Label>
                  <Form.Check
                    type='checkbox'
                    name='explanation'
                    id='explanation'
                    label='Textual explanation'
                    ref={register({ required: false })}
                  />
                  <Form.Check
                    type='checkbox'
                    name='mcq'
                    id='MCQ'
                    label='MCQ'
                    ref={register({ required: false })}
                  />
                  <Form.Check
                    type='checkbox'
                    name='video'
                    id='video'
                    label='Video'
                    ref={register({ required: false })}
                  />
                  <Form.Check
                    type='checkbox'
                    name='image'
                    id='image'
                    label='Image'
                    ref={register({ required: false })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Label>Delete all</Form.Label>
                <Form.Check
                  type='checkbox'
                  name='allContent'
                  id='allContent'
                  label='Delete All Content'
                  ref={register({ required: false })}
                />
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button className='w-25' variant='danger' type='submit'>
                  Delete
                </Button>
                <Toaster />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteContent;
