import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert, Container } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/AuthContext';
import { useFirebase } from '../../contexts/FirebaseContext';
import { loadStripe } from '@stripe/stripe-js';
import '../../stripe.css';

function UpdateAccount() {
  const [products, setProducts] = useState([]);
  const [subscription, setSubscription] = useState(null);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { currentUser, updateEmail, updatePassword, updateProfile, admin } =
    useAuth();
  const { updateUser, getSubscription, getProducts, getCheckout } =
    useFirebase();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: currentUser.email,
      displayName: currentUser.displayName,
    },
  });

  useEffect(() => {
    fetchSubscription(currentUser.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.uid]);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubscription = async (uid) => {
    await getSubscription(uid)
      .then((querySnapshot) => {
        querySnapshot.forEach(async (subscription) => {
          setSubscription({
            role: subscription.data().role,
            current_period_end: subscription.data().current_period_end.seconds,
            current_period_start:
              subscription.data().current_period_start.seconds,
          });
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const fetchProducts = async () => {
    await getProducts().then((querySnapshot) => {
      const products = {};
      querySnapshot.forEach(async (productDoc) => {
        products[productDoc.id] = productDoc.data();
        const priceSnap = await productDoc.ref.collection('prices').get();
        priceSnap.docs.forEach((price) => {
          products[productDoc.id].prices = {
            priceId: price.id,
            priceData: price.data(),
          };
        });
      });
      setProducts(products);
    });
  };

  const onUpdate = async (data) => {
    if (data.password !== data.password2) {
      return setError('Passwords do not match');
    }

    setLoading(true);
    setError('');

    if (data.email !== currentUser.email) {
      updateEmail(data.email)
        .then(updateUser(currentUser.uid, { email: data.email }))
        .then(
          toast.success(`Your email has been updated to ${data.email}.`, {
            duration: 2000,
            icon: '👏',
          })
        )
        .catch((error) => {
          setError(error.message);
        });
    }

    if (data.displayName !== currentUser.displayName) {
      const update = { displayName: data.displayName };
      updateProfile(update)
        .then(updateUser(currentUser.uid, update))
        .then(
          toast.success(
            `Your username has been updated to ${data.displayName}.`,
            {
              duration: 2000,
              icon: '👏',
            }
          )
        )
        .catch((error) => {
          setError(error.message);
        });
    }

    if (data.password) {
      updatePassword(data.password).then(
        toast.success('Your password has been updated.', {
          duration: 2000,
          icon: '👏',
        })
      );
    }

    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const loadCheckout = async (priceId) => {
    const docRef = await getCheckout(currentUser.uid, {
      price: priceId,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();

      if (error) {
        alert(`An error occured: ${error.message}`);
      }

      if (sessionId) {
        const stripe = await loadStripe(
          'pk_test_51IrJOUHyqtEdGogngC1vGNCaFqUUblShxy6FTjcrdTFpjoK2BkC4CQWfaGEef5yVcsmwCkVg3R0Cwt1qnlN24yOY00SERNuaPl'
        );
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  return (
    <>
      <Container
        fluid
        className='justify-content-center d-flex align-items-center'
        style={{
          height: '82vh',
        }}
      >
        <Card style={{ minWidth: '35%', maxWidth: '400px' }}>
          <Card.Body>
            <h2 className='text-center mb-4'>Update Account</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit(onUpdate)}>
              {/* Email Address */}
              <Form.Group controlId='formUpdateEmail'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  ref={register({ required: true })}
                />
              </Form.Group>

              {/* Display Name */}
              <Form.Group controlId='formUpdateDisplayName'>
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  type='text'
                  name='displayName'
                  ref={register({ required: false })}
                />
              </Form.Group>

              {/* Password */}
              <Form.Group controlId='formUpdatePassword'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  placeholder='Leave blank to keep the same'
                  ref={register({ required: false })}
                />
              </Form.Group>

              {/* Password Confirmation*/}
              <Form.Group controlId='formUpdatePasswordConfirm'>
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control
                  type='password'
                  name='password2'
                  placeholder='Leave blank to keep the same'
                  ref={register({ required: false })}
                />
              </Form.Group>
              <Button disabled={loading} className='w-100' type='submit'>
                Update
              </Button>
              <Toaster />
            </Form>
          </Card.Body>
        </Card>
      </Container>

      {!admin && (
        <div className='paymentScreen'>
          <br />
          {subscription && (
            <p>
              Renewal date:{' '}
              {new Date(
                subscription?.current_period_end * 1000
              ).toLocaleDateString()}
            </p>
          )}

          {Object.entries(products).map(([productId, productData]) => {
            const isCurrentPackage = productData.name
              ?.toLowerCase()
              .includes(subscription?.role);

            return (
              <div
                key={productId}
                className={`${
                  isCurrentPackage && 'paymentScreen__plan--disabled'
                } paymentScreen__plan`}
              >
                <div className='paymentScreen__info'>
                  <h5>{productData.name}</h5>
                </div>
                <button
                  onClick={() =>
                    !isCurrentPackage &&
                    loadCheckout(productData.prices.priceId)
                  }
                >
                  {isCurrentPackage ? 'Current Package' : 'Subscribe'}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default UpdateAccount;
