import React, { useState, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import RequestTable from './RequestTable';

const ManageRequests = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [requests, setRequests] = useState([]);

  const { getRequests, deleteRequest } = useFirebase();
  const { signUp } = useAuth();

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch Repairs
  const fetchRequests = async () => {
    setLoading(true);

    await getRequests()
      .then((list) => {
        setRequests(
          list.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onAccept = async (request) => {
    await signUp(request.firstName, request.lastName, request.email)
      .then(() => {
        toast.success(
          `${request.firstName}'s request has been accepted. The account has just been created.`,
          {
            duration: 2000,
            icon: '👏',
          }
        );
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const onDecline = async (request) => {
    await deleteRequest(request)
      .then(() => {
        toast.success(`${request.firstName}'s request has been declined.`, {
          duration: 2000,
          icon: '👏',
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Container>
      <Row
        className='justify-content-center d-flex align-items-center mt-5'
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <RequestTable
            requests={requests}
            onAccept={onAccept}
            onDecline={onDecline}
            error={error}
          />
        )}
      </Row>
    </Container>
  );
};

export default ManageRequests;
