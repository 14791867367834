import React, { useState } from 'react';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFirebase } from '../../../contexts/FirebaseContext';

const AddLesson = ({ track, session, fundamental, docLength }) => {
  const [showAddLesson, setShowAddLesson] = useState(false);
  const { addLesson } = useFirebase();

  const { register, reset, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    let lesson = `Lesson-${docLength + 1}`;
    await addLesson(track, session, fundamental, lesson, {
      name: data.lesson,
    })
      .then(reset())
      .then(() => {
        toast.success(`${data.lesson} has been added!`, {
          duration: 2000,
          icon: '👏',
        });
      })
      .then(
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className='btn-primary ml-2 mt-2 mb-2'
          onClick={() => setShowAddLesson(true)}
        >
          Add Lesson
        </Button>
      </div>

      <Modal
        size='md'
        data-backdrop='static'
        data-keyboard='false'
        animation={true}
        show={showAddLesson}
        onHide={() => setShowAddLesson(!showAddLesson)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Lesson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                {/* Lesson */}
                <Form.Label>Lesson</Form.Label>
                <Form.Group controlId='formAddLesson'>
                  <Form.Control
                    type='text'
                    name='lesson'
                    placeholder='What lesson?'
                    ref={register({ required: true })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button className='w-25' variant='primary' type='submit'>
                  Add
                </Button>
                <Toaster />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddLesson;
