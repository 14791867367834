import React, { useContext } from 'react';
import { db } from '../components/Firebase/firebase';

export const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider({ children }) {
  function updateUser(uid, data) {
    return db.collection('users').doc(uid).set(data, { merge: true });
  }

  //* Subscription API *//
  function getSubscription(uid) {
    return db
      .collection('customers')
      .doc(uid)
      .collection('subscriptions')
      .get();
  }

  //* Products API *//
  function getProducts() {
    return db.collection('products').where('active', '==', true).get();
  }

  //* Checkout API *//
  function getCheckout(uid, data) {
    return db
      .collection('customers')
      .doc(uid)
      .collection('checkout_sessions')
      .add(data);
  }

  //* Request User API *//
  function addRequest(data) {
    return db.collection('Requests').add(data);
  }

  function getRequests() {
    return db.collection('Requests').get();
  }

  function deleteRequest(request) {
    db.collection('Requests').doc(request).delete();
  }

  //* Track API *//
  function getAllTracks() {
    return db.collection('Tracks').get();
  }

  function addTrack(track, data) {
    return db.collection('Tracks').doc(track).set(data);
  }

  function deleteTrack(track) {
    return db
      .collection('Tracks')
      .where('name', '==', `${track}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
          console.log(doc.data());
        });
      });
  }

  //* Session API *//
  function getAllSessions(track) {
    return db.collection('Tracks').doc(track).collection('Sessions').get();
  }

  function addSession(track, session, data) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .set(data);
  }

  function deleteSession(track, session) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .where('name', '==', `${session}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
          console.log(doc.data());
        });
      });
  }

  //* Fundamental API *//

  function getAllFundamentals(track, session) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .get();
  }

  function addFundamental(track, session, fundamental, data) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .set(data);
  }

  function deleteFundamental(track, session, fundamental) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .where('name', '==', `${fundamental}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
          console.log(doc.data());
        });
      });
  }

  //* Lesson API *//

  function addLesson(track, session, fundamental, lesson, data) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .doc(lesson)
      .set(data);
  }

  function getAllLessons(track, session, fundamental) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .get();
  }

  function deleteLesson(track, session, fundamental, lesson) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .where('name', '==', `${lesson}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
          console.log(doc.data());
        });
      });
  }

  //* Content API *//

  function getContent(track, session, fundamental, lesson, page) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .doc(lesson)
      .collection('Pages')
      .doc(page)
      .get();
  }

  function getAllContent(track, session, fundamental, lesson) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .doc(lesson)
      .collection('Pages')
      .get();
  }

  function updateContent(track, session, fundamental, lesson, page, data) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .doc(lesson)
      .collection('Pages')
      .doc(page)
      .set(data, { merge: true });
  }

  function deleteContent(track, session, fundamental, lesson, page) {
    return db
      .collection('Tracks')
      .doc(track)
      .collection('Sessions')
      .doc(session)
      .collection('Fundamentals')
      .doc(fundamental)
      .collection('Lessons')
      .doc(lesson)
      .collection('Pages')
      .doc(page)
      .delete();
  }

  const value = {
    updateUser,
    getSubscription,
    getProducts,
    getCheckout,
    addRequest,
    getRequests,
    deleteRequest,
    getAllTracks,
    addTrack,
    deleteTrack,
    getAllSessions,
    addSession,
    deleteSession,
    getAllFundamentals,
    addFundamental,
    deleteFundamental,
    addLesson,
    deleteLesson,
    getAllLessons,
    getContent,
    getAllContent,
    updateContent,
    deleteContent,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
}
