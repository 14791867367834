import React from 'react';
import { useState } from 'react';
import { Card, Form, Button, Alert, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import * as ROUTES from '../../constants/routes';

const SignInForm = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // after login, navigate to Home
  const handleSubmit = (e) => {
    e.preventDefault();

    login(values.email, values.password)
      .then(() => {
        setValues({ email: '', password: '' });
        setTimeout(() => {
          navigate(ROUTES.HOME);
        }, 1);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Container
      fluid
      className='justify-content-center d-flex align-items-center'
      style={{
        height: '92vh',
      }}
    >
      <div style={{ minWidth: '35%', maxWidth: '400px' }}>
        <Card>
          <Card.Body>
            <h2 className='text-center mb-4'>Log In</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id='email'>
                <Form.Control
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={values.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group id='password'>
                <Form.Control
                  type='password'
                  name='password'
                  placeholder='Password'
                  value={values.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button className='w-100' type='submit'>
                Log In
              </Button>
            </Form>
            <div className='w-100 text-center mt-3'>
              <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
            </div>
          </Card.Body>
        </Card>
        <div className='w-100 text-center mt-1'>
          Request account? <Link to={ROUTES.SIGN_UP_REQUEST}>Sign Up</Link>
        </div>
      </div>
    </Container>
  );
};

export default SignInForm;
