import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import RequestModal from './RequestModal';

const RequestInfo = ({ request, labelId, onUpdate, onDelete }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell></TableCell>
        <TableCell
          onClick={handleShow}
          component='th'
          id={labelId}
          scope='row'
          padding='none'
        >
          {request.firstName}
        </TableCell>
        <TableCell onClick={handleShow}>{request.lastName}</TableCell>
        <TableCell onClick={handleShow} align='right'>
          {request.club}
        </TableCell>
        <TableCell onClick={handleShow} align='right'>
          {request.createdAt}
        </TableCell>
      </TableRow>

      <RequestModal
        request={request}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
    </>
  );
};

export default RequestInfo;
