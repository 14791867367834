import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Landing from '../Landing';
import SignInForm from '../SignIn';
import ForgotPasswordForm from '../PasswordForget';
import HomePage from '../Home';
import { MakeAdmin, SignUp } from '../Admin';
import {
  Tracks,
  Track,
  Fundamental,
  Session,
  Lesson,
} from '../Tracks/HierarchyComponents';
import { Navigation } from '../Navigation';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from '../../contexts/AuthContext';
import { FirebaseProvider } from '../../contexts/FirebaseContext';
import * as ROUTES from '../../constants/routes';
import UpdateProfile from '../Account/UpdateAccount';
import SignUpRequest from '../SignUp/SignUpRequest';
import { ManageRequests } from '../Admin/Request';

function App() {
  return (
    <>
      <AuthProvider>
        <FirebaseProvider>
          <Router>
            <Navigation />
            <Routes>
              <Route path={ROUTES.LANDING} element={<Landing />} />
              <Route path={ROUTES.SIGN_IN} element={<SignInForm />} />
              <Route
                path={ROUTES.SIGN_UP_REQUEST}
                element={<SignUpRequest />}
              />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                element={<ForgotPasswordForm />}
              />
              <PrivateRoute
                path={ROUTES.HOME}
                navigate={ROUTES.SIGN_IN}
                element={<HomePage />}
              />
              <PrivateRoute
                path={ROUTES.UPDATE_ACCOUNT}
                navigate={ROUTES.SIGN_IN}
                element={<UpdateProfile />}
              />
              <PrivateRoute
                path={ROUTES.SIGN_UP}
                navigate={ROUTES.SIGN_IN}
                element={<SignUp />}
              />
              <PrivateRoute
                path={ROUTES.MAKE_ADMIN}
                navigate={ROUTES.SIGN_IN}
                element={<MakeAdmin />}
              />
              <PrivateRoute
                path={ROUTES.REQUESTS}
                navigate={ROUTES.SIGN_IN}
                element={<ManageRequests />}
              />
              <PrivateRoute
                path={ROUTES.TRACKS}
                navigate={ROUTES.SIGN_IN}
                element={<Tracks />}
              />
              <PrivateRoute
                path={ROUTES.TRACK}
                navigate={ROUTES.SIGN_IN}
                element={<Track />}
              />
              <PrivateRoute
                path={ROUTES.SESSION}
                navigate={ROUTES.SIGN_IN}
                element={<Session />}
              />
              <PrivateRoute
                path={ROUTES.FUNDAMENTAL}
                navigate={ROUTES.SIGN_IN}
                element={<Fundamental />}
              />
              <PrivateRoute
                path={ROUTES.LESSON}
                navigate={ROUTES.SIGN_IN}
                element={<Lesson />}
              />
            </Routes>
          </Router>
        </FirebaseProvider>
      </AuthProvider>
    </>
  );
}

export default App;
