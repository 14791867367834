import React, { useState } from 'react';
import { Card, Form, Button, Alert, Container, Col } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFirebase } from '../../contexts/FirebaseContext';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const SignUpRequest = () => {
  const { register, reset, handleSubmit } = useForm();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { addRequest } = useFirebase();

  const onSubmit = async (data) => {
    setError('');
    setLoading(true);

    let date = { day: data.day, month: data.month, year: data.year };

    const time = Date.now();
    const timestamp = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(time);

    let userInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      club: data.club,
      dateOfBirth: date,
      createdAt: timestamp,
    };
    await addRequest(userInfo)
      .then(() => {
        reset();
        toast.success(
          `${data.firstName}'s account has been requested. You will receive an email when the decision has been made.`,
          {
            duration: 5000,
            icon: '👏',
          }
        );
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Container
      fluid
      className='justify-content-center d-flex align-items-center mt-5'
      style={{
        display: 'flex',
      }}
    >
      <div style={{ minWidth: '35%', maxWidth: '400px' }}>
        <Card>
          <Card.Body>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h2>Please fill in your information.</h2>
              <br></br>

              {/* First name */}
              <Form.Group controlId='signUpFirstName'>
                <Col>
                  <Form.Control
                    type='text'
                    name='firstName'
                    placeholder='First Name'
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              {/* Last name */}
              <Form.Group controlId='signUpLastName'>
                <Col>
                  <Form.Control
                    type='text'
                    name='lastName'
                    placeholder='Last Name'
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              {/* Email Address */}
              <Form.Group controlId='signUpEmail'>
                <Col>
                  <Form.Control
                    type='email'
                    placeholder='name@example.com'
                    name='email'
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              {/* Phone Number */}
              <Form.Group controlId='signUpPhoneNumber'>
                <Col>
                  <Form.Control
                    type='text'
                    placeholder='Phone Number'
                    name='phoneNumber'
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              {/* Club Name */}
              <Form.Group controlId='signUpClubName'>
                <Col>
                  <Form.Control
                    type='text'
                    placeholder='Football Club'
                    name='club'
                    ref={register({ required: true })}
                  />
                </Col>
              </Form.Group>

              {/* Date of Birth */}
              <Form.Label className='ml-3'>Date of Birth</Form.Label>
              <Form.Row>
                <Form.Group
                  as={Col}
                  className='ml-3'
                  controlId='SignUpDateOfBirthDay'
                >
                  <Form.Control
                    type='text'
                    placeholder='Day'
                    name='day'
                    ref={register({ required: true })}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='SignUpDateOfBirthMonth'>
                  <Form.Control
                    type='text'
                    placeholder='Month'
                    name='month'
                    ref={register({ required: true })}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className='mr-3'
                  controlId='SignUpDateOfBirthYear'
                >
                  <Form.Control
                    type='text'
                    placeholder='Year'
                    name='year'
                    ref={register({ required: true })}
                  />
                </Form.Group>
              </Form.Row>

              <Button
                disabled={loading}
                variant='primary'
                className='w-100'
                type='submit'
              >
                Submit
              </Button>
              <Toaster />
            </Form>
          </Card.Body>
        </Card>
        <div className='w-100 text-center mt-1'>
          <Link to={ROUTES.SIGN_IN}>Back</Link>
        </div>
      </div>
    </Container>
  );
};

export default SignUpRequest;
