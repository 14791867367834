import React, { useState } from 'react';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFirebase } from '../../../contexts/FirebaseContext';

const AddSession = ({ track, docLength }) => {
  const [showAddSession, setShowAddSession] = useState(false);
  const { addSession } = useFirebase();

  const { register, reset, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    let session = `Session-${docLength + 1}`;
    await addSession(track, session, {
      name: data.session,
    })
      .then(reset())
      .then(() => {
        toast.success(`${data.session} has been added!`, {
          duration: 2000,
          icon: '👏',
        });
      })
      .then(
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className='btn-primary ml-2 mt-2 mb-2'
          onClick={() => setShowAddSession(true)}
        >
          Add Session
        </Button>
      </div>

      <Modal
        size='md'
        data-backdrop='static'
        data-keyboard='false'
        animation={true}
        show={showAddSession}
        onHide={() => setShowAddSession(!showAddSession)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                {/* Session */}
                <Form.Label>Session</Form.Label>
                <Form.Group controlId='formAddSession'>
                  <Form.Control
                    type='text'
                    name='session'
                    placeholder='What session?'
                    ref={register({ required: true })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button className='w-25' variant='primary' type='submit'>
                  Add
                </Button>
                <Toaster />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSession;
