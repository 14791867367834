import React, { useContext, useState, useEffect } from 'react';
import { auth, functions, db } from '../components/Firebase/firebase';

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [subscribed, setSubscribed] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      user &&
        user.getIdTokenResult().then((idTokenResult) => {
          idTokenResult.claims.admin ? setAdmin(true) : setAdmin(false);
        });
      setCurrentUser(user);
      user && !admin && checkSubscription(user.uid);
      setLoading(false);
    });

    return unsubscribe;
  }, [admin]);

  function checkSubscription(uid) {
    db.collection('customers')
      .doc(uid)
      .collection('subscriptions')
      .get()
      .then((sub) => {
        sub.docs.length > 0 ? setSubscribed(true) : setSubscribed(false);
        console.log(sub.docs.length);
      });
  }

  function updateProfile(data) {
    return auth.currentUser.updateProfile(data);
  }

  function signUp(firstName, lastName, email) {
    const createUser = functions.httpsCallable('createUser');
    return createUser({
      email: email,
      firstName: firstName,
      lastName: lastName,
    })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: 'https://courses.tactalyse.com/signin',
      handleCodeInApp: false,
    };
    return auth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  const value = {
    updateProfile,
    currentUser,
    subscribed,
    admin,
    signUp,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
