import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';
import * as ROUTES from '../../constants/routes';

const SignOutButton = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    logout();
    navigate(ROUTES.LANDING);
  };

  return (
    <Button variant='outline-info' onClick={handleClick}>
      Sign Out
    </Button>
  );
};

export default SignOutButton;
