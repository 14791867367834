import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import * as ROUTES from '../../../constants/routes';

import { useFirebase } from '../../../contexts/FirebaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import { AddSession, DeleteSession } from '../SessionComponents';

function Track() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docLength, setDocLength] = useState(0);

  const { getAllSessions } = useFirebase();
  const { admin } = useAuth();

  const trackPath = useParams().track;
  const trackId = trackPath.charAt(0).toUpperCase() + trackPath.slice(1);

  useEffect(() => {
    setLoading(true);
    fetchAllSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllSessions = async () => {
    await getAllSessions(trackId)
      .then((list) => {
        setDocLength(list.docs.length);

        list.docs.forEach((doc) => {
          let path = doc.id.toLowerCase();
          sessions.push({ ...doc.data(), id: doc.id, path: path });
        });
        setSessions(sessions);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Col>
        {admin && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AddSession track={trackId} docLength={docLength} />
            <DeleteSession track={trackId} />
          </div>
        )}
        {!loading &&
          sessions.map((session) => (
            <Row className='justify-content-center mt-2'>
              <div key={session.id}>
                <Button
                  style={{ height: '45px', width: '700px' }}
                  variant='outline-primary'
                  className='mt-1 mr-2'
                  as={Link}
                  to={ROUTES.TRACKS.concat('/', trackPath).concat(
                    '/',
                    session.path
                  )}
                  href={ROUTES.TRACKS.concat('/', trackPath).concat(
                    '/',
                    session.path
                  )}
                >
                  {session.name}
                </Button>
              </div>
            </Row>
          ))}
      </Col>
    </div>
  );
}

export default Track;
