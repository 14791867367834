import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { functions } from '../Firebase/firebase';

function MakeAdmin() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (data) => {
    const { value } = data.target;
    setEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setError('');

    setLoading(true);

    const addAdminRole = functions.httpsCallable('addAdminRole');
    addAdminRole({ email: email })
      .then((result) => {
        console.log(result);
        toast.success(`${email}'s is an admin.`, {
          duration: 2000,
          icon: '👏',
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Container
      fluid
      className='justify-content-center d-flex align-items-center'
      style={{
        height: '92vh',
      }}
    >
      <Card style={{ minWidth: '35%', maxWidth: '400px' }}>
        <Card.Body>
          <h2 className='text-center mb-4'>Make Admin</h2>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id='formMakeAdmin'>
              <Form.Control
                type='text'
                name='email'
                placeholder='Email Address'
                value={email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button disabled={loading} className='w-100' type='submit'>
              Make Admin
            </Button>
            <Toaster />
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default MakeAdmin;
