export const LANDING = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP_REQUEST = '/signup';
export const PASSWORD_FORGET = '/pw-forget';
export const HOME = '/home';

export const MAKE_ADMIN = '/admin/make-admin';
export const SIGN_UP = '/admin/signup';
export const REQUESTS = '/admin/requests';

export const UPDATE_ACCOUNT = '/account/update';

export const TRACKS = '/tracks';
export const TRACK = '/tracks/:track';
export const SESSION = '/tracks/:track/:session';
export const FUNDAMENTAL = '/tracks/:track/:session/:fundamental';
export const LESSON = '/tracks/:track/:session/:fundamental/:lesson';
