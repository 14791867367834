import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import * as ROUTES from '../../../constants/routes';

import { useFirebase } from '../../../contexts/FirebaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import { AddLesson, DeleteLesson } from '../LessonComponents';

const Fundamental = () => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docLength, setDocLength] = useState(0);

  const { getAllLessons } = useFirebase();
  const { admin } = useAuth();

  const trackPath = useParams().track;
  const sessionPath = useParams().session;
  const fundamentalPath = useParams().fundamental;
  const trackId = trackPath.charAt(0).toUpperCase() + trackPath.slice(1);
  const sessionId = sessionPath.charAt(0).toUpperCase() + sessionPath.slice(1);
  const fundamentalId =
    fundamentalPath.charAt(0).toUpperCase() + fundamentalPath.slice(1);

  useEffect(() => {
    setLoading(true);
    fetchAllLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllLessons = async () => {
    await getAllLessons(trackId, sessionId, fundamentalId)
      .then((list) => {
        setDocLength(list.docs.length);

        list.docs.forEach((doc) => {
          let path = doc.id.toLowerCase();
          lessons.push({ ...doc.data(), id: doc.id, path: path });
        });
        setLessons(lessons);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Col>
        {admin && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AddLesson
              track={trackId}
              session={sessionId}
              fundamental={fundamentalId}
              docLength={docLength}
            />
            <DeleteLesson
              track={trackId}
              session={sessionId}
              fundamental={fundamentalId}
            />
          </div>
        )}
        {!loading &&
          lessons.map((lesson) => (
            <Row className='justify-content-center d-flex align-items-center ml-2 mr-2 mt-2'>
              <div key={lesson.id}>
                <Button
                  style={{ height: '45px', width: '700px' }}
                  variant='outline-primary'
                  className='w-30 mt-1 mr-2'
                  as={Link}
                  to={ROUTES.TRACKS.concat('/', trackPath)
                    .concat('/', sessionPath)
                    .concat('/', fundamentalPath)
                    .concat('/', lesson.path)}
                  href={ROUTES.TRACKS.concat('/', trackPath)
                    .concat('/', sessionPath)
                    .concat('/', fundamentalPath)
                    .concat('/', lesson.path)}
                >
                  {lesson.name}
                </Button>
              </div>
            </Row>
          ))}
      </Col>
    </div>
  );
};

export default Fundamental;
