import React, { useState } from 'react';
import { Card, Form, Button, Alert, Container } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';

const SignUp = () => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const { firstName, lastName, email } = values;
  const { signUp } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setError('');
    setLoading(true);

    signUp(firstName, lastName, email)
      .then(() => {
        setValues({
          firstName: '',
          lastName: '',
          email: '',
        });
        toast.success(`${firstName}'s user has been created.`, {
          duration: 2000,
          icon: '👏',
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Container
      fluid
      className='justify-content-center d-flex align-items-center'
      style={{
        height: '92vh',
      }}
    >
      <Card style={{ minWidth: '35%', maxWidth: '400px' }}>
        <Card.Body>
          <h2 className='text-center mb-4'>Sign Up</h2>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id='firstName'>
              <Form.Control
                type='text'
                name='firstName'
                placeholder='First Name'
                value={firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group id='lastName'>
              <Form.Control
                type='text'
                name='lastName'
                placeholder='Last Name'
                value={lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group id='email'>
              <Form.Control
                type='email'
                name='email'
                placeholder='Email'
                value={email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button disabled={loading} className='w-100' type='submit'>
              Sign Up
            </Button>
            <Toaster />
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SignUp;
