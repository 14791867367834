import React, { useState } from 'react';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useFirebase } from '../../../contexts/FirebaseContext';

const AddFundamental = ({ track, session, docLength }) => {
  const [showAddFundamental, setShowAddFundamental] = useState(false);
  const { addFundamental } = useFirebase();

  const { register, reset, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    let fundamental = `Fundamental-${docLength + 1}`;
    await addFundamental(track, session, fundamental, {
      name: data.fundamental,
    })
      .then(reset())
      .then(() => {
        toast.success(`${data.fundamental} has been added!`, {
          duration: 2000,
          icon: '👏',
        });
      })
      .then(
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className='btn-primary ml-2 mt-2 mb-2'
          onClick={() => setShowAddFundamental(true)}
        >
          Add Fundamental
        </Button>
      </div>

      <Modal
        size='md'
        data-backdrop='static'
        data-keyboard='false'
        animation={true}
        show={showAddFundamental}
        onHide={() => setShowAddFundamental(!showAddFundamental)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Fundamental</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                {/* Fundamental */}
                <Form.Label>Fundamental</Form.Label>
                <Form.Group controlId='formAddFundamental'>
                  <Form.Control
                    type='text'
                    name='fundamental'
                    placeholder='What fundamental?'
                    ref={register({ required: true })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button className='w-25' variant='primary' type='submit'>
                  Add
                </Button>
                <Toaster />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFundamental;
